import React from 'react'

import * as footerStyles from './footer.module.css'

import mailtoImage from '../../images/mailto-100px.png'
import linkedinImage from '../../images/In-White-121px.png'
import twitterImage from '../../images/Twitter-logo-120px.png'
import githubImage from '../../images/GitHub-Mark-Light-120px-plus.png'
import dribbbleImage from '../../images/Dribbble-120px.png'

const Footer = () => (
  <footer className={footerStyles.container}>
    <div className={footerStyles.block}>
      <div className={footerStyles.contacts}>
        <a className={footerStyles.mailto} href="mailto:laitine@iki.fi">
          <img src={mailtoImage} title="Mail" alt="Mail" />
        </a>
        <a
          className={footerStyles.linkedin}
          href="https://www.linkedin.com/in/nlaitinen"
        >
          <img src={linkedinImage} title="Linkedin" alt="Linkedin" />
        </a>
        <a
          className={footerStyles.twitter}
          href="https://twitter.com/laitineio"
        >
          <img src={twitterImage} title="Twitter" alt="Twitter" />
        </a>
        <a className={footerStyles.github} href="https://github.com/laitine">
          <img src={githubImage} title="Github" alt="Github" />
        </a>
        <a
          className={footerStyles.dribbble}
          href="https://dribbble.com/laitine"
        >
          <img src={dribbbleImage} title="Dribbble" alt="Dribbble" />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
