import React from 'react'

import * as eventStyles from './event.module.css'

const Event = (props) => (
  <section className={eventStyles.container}>
    <time className={eventStyles.time}>{props.date}</time>
    <a className={eventStyles.heading} href={props.link}>
      <h3>{props.title}</h3>
    </a>
    <p dangerouslySetInnerHTML={{ __html: props.content }} />
  </section>
)

export default Event
