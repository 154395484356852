import React from 'react'

import Layout from '../components/layout'
import Footer from '../components/footer'
import Event from '../components/event'

const events = [
  {
    date: '10.6.2021',
    title: 'Adaptable Org Design for Resilient Times, Design at Scale',
    link:
      'https://rosenfeldmedia.com/design-at-scale-2021/sessions/adaptable-org-design-for-resilient-times',
    content:
      'Talk about growing the impact of design at a government-controlled organization in a regulated industry.',
  },
  {
    date: '22.9.2020',
    title:
      'Ask-Me-Anything session for introduction course, University of Helsinki',
    link: 'https://courses.helsinki.fi/en/tkt10001/124961057',
    content:
      'Online session where students can ask anything about work and careers in the digital industry.',
  },
  {
    date: '3.12.2019',
    title:
      'Digital Product Design: Building software that stays alive, University of Helsinki',
    link: 'https://ohjelmistotuotanto-hy.github.io',
    content:
      'Lecture on digital product design. History, process and methods of Lean-Agile UX Design.',
  },
  {
    date: '25.9.2018',
    title: 'Industry Panel, University of Helsinki',
    link: 'https://courses.helsinki.fi/en/tkt10001/124961057',
    content:
      'Panel to students on how to build a career in the digital industry.',
  },
  {
    date: '14.3.2018',
    title: 'Sustainable Design Systems, DS Conf',
    link: 'https://dsconference.com/dsconf-conference-day',
    content:
      'Talk about the sustainability of Design Systems. You can watch it on <a href="https://vimeo.com/263315804">Vimeo</a>.',
  },
]

const SpeakingPage = () => (
  <Layout>
    <article>
      <h3 className="centered margin-bottom">
        Occasionally I speak at events. Some listed here.
      </h3>
      {events.map((event, i) => (
        <Event
          key={i}
          date={event.date}
          title={event.title}
          link={event.link}
          content={event.content}
        />
      ))}
    </article>
    <Footer />
  </Layout>
)

export default SpeakingPage
